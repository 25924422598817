<style lang="scss">
  .networks-page {
    &__network-table{
      .card-header{
        justify-content: flex-start !important;
      }
    }

    &__network-section {
      padding: 20px 0;
      border-bottom: 2px solid #5e50ee;
    }
  }
</style>
<!--eslint-disable-->
<template>
  <div class="networks-page">
    <b-card v-if="$can('Create', 'Network')">
      <b-button @click="$bvModal.show('modal-add-network')" class="d-flex ml-auto btn-success">Create Network</b-button>
    </b-card>

    <b-card class="networks-page__network-table">
      <b-tabs v-if="networks.length > 0" pills card vertical lazy>
        <b-tab v-for="network in networks" :key="network.uid" :title="network.name">
          <section class="networks-page__network-section pt-0">
            <h3 class="font-weight-bolder">Network Details</h3>

            <div class="row">
              <div class="col-3">
                <h5 class="font-weight-bolder">Name</h5>
                <p>{{network.name}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Auto connect</h5>
                <p>{{network.auto_connect}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Encrypted passphrase</h5>
                <p>{{network.encrypted_passphrase}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Security</h5>
                <p>{{network.security}}</p>
              </div>
            </div>
          </section>

          <section class="networks-page__network-section" v-if="network.security === 'WEP-8021X' || network.security === 'WPA-EAP'">
            <h3 class="font-weight-bolder">EAP Details</h3>

            <div class="row">
              <div class="col-3">
                <h5 class="font-weight-bolder">Eap client cert type</h5>
                <p>{{network.eap_client_cert_type}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Eap encrypted password</h5>
                <p>{{network.eap_encrypted_password}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Eap identity</h5>
                <p>{{network.eap_identity}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Eap inner</h5>
                <p>{{network.eap_inner}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Eap outer</h5>
                <p>{{network.eap_outer}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Eap save credentials</h5>
                <p>{{network.eap_save_credentials}}</p>
              </div>
            </div>
          </section>

          <section class="networks-page__network-section">
            <h3 class="font-weight-bolder">SSID Details</h3>

            <div class="row">
              <div class="col-3">
                <h5 class="font-weight-bolder">Ssid</h5>
                <p>{{network.ssid}}</p>
              </div>
              <div class="col-3">
                <h5 class="font-weight-bolder">Ssid hidden</h5>
                <p>{{network.ssid_hidden}}</p>
              </div>
            </div>
          </section>

          <div class="d-inline-flex justify-content-end full-width mt-2">
            <b-btn v-if="$can('Update', 'Network')"
                   @click="showNetworkModal(network, 'modal-update-network')"
                   variant="primary"
                   class="m-0 mr-2">Update</b-btn>
            <b-btn v-if="$can('Delete', 'Network')"
                   @click="showNetworkModal(network, 'modal-delete-network')"
                   variant="danger"
                   disabled
                   class="m-0">Delete</b-btn>
          </div>

          <model-activities :no-body="true" model="network" :uid="network.uid"></model-activities>

        </b-tab>
      </b-tabs>
      <p v-else>No networks available</p>
    </b-card>

    <b-modal id="modal-add-network" title="Add new network" size="lg" hide-footer>
      <add-network @refresh="refreshNetworks()" @close="closeModals()"></add-network>
    </b-modal>

    <b-modal id="modal-update-network" size="lg" title="Update network" hide-footer>
      <update-network v-if="selectedNetwork" :existing-network="selectedNetwork" @refresh="refreshNetworks()" @close="closeModals()"></update-network>
    </b-modal>

    <b-modal id="modal-delete-network" title="Delete network" hide-footer>
      <delete-modal
        v-if="selectedNetwork"
        @close="closeModals()" @delete="deleteNetwork(selectedNetwork)"
        :subtitle="selectedNetwork.name"
        title="Are you sure you wish to delete network"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import ModelActivities from '../activities/ModelActivities.vue';
  import NetworkService from '../../services/NetworkService';
  import AddNetwork from './sections/AddNetwork.vue';
  import UpdateNetwork from './sections/UpdateNetwork.vue';

  export default {
    name: 'Networks',
    components: {
      AddNetwork,
      UpdateNetwork,
      ModelActivities,
    },
    props: {},
    data() {
      return {
        selectedNetwork: null,
        networks: [],
      };
    },
    mounted() {
      this.getNetworks();
    },
    methods: {
      refreshNetworks() {
        this.closeModals();
        this.getNetworks();
      },
      closeModals() {
        this.$bvModal.hide('modal-add-network');
        this.$bvModal.hide('modal-update-network');
        this.$bvModal.hide('modal-delete-network');
      },
      getNetworks() {
        NetworkService.getNetworks(this.filters).then(res => {
          this.networks = res.data;
        }).catch(() => {
          this.$toast.error('Could not get networks, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      setSelectedNetwork(network) {
        this.selectedNetwork = network;
      },
      showNetworkModal(network, modalName) {
        this.setSelectedNetwork(network);
        this.$bvModal.show(modalName);
      },
      deleteNetwork(network) {
        NetworkService.deleteNetwork(network.uid).then(() => {
          this.$toast.success(`Deleted network ${network.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshNetworks();
        }).catch(() => {
          this.$toast.error('Could not delete network, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
    },
  };
</script>
